import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { usePageStrings } from "../../../common/hooks";
import { ActionStatus, AppDispatch, RootState, useAppDispatch, useAppSelector } from "../../../store";
import { appLogService } from "../../../store/slices/appLog";
import { signInWithAzure, signInWithOkta } from "../../../store/slices/authorization";
import { Divider, FilledTonalButton, Text } from "../../../common/components";
import SignInUserAndPassword from "./signInUserAndPassword/SignInUserAndPassword";
import urlService from "../../../common/services/url.service";
import { createMsalInstance, loginRequest } from "../../../authAzureConfig";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { Authentication } from "../../../viewModels/application/configuration/authentication";

import styles from "./SignIn.module.scss";
import { SignInBannerMessage } from "./signInBannerMessage/SignInBannerMessage";

export function SignIn() {

    const dispatch = useAppDispatch();

    const pageStrings = usePageStrings();
    const [searchParams] = useSearchParams();
    const authorization = useAppSelector(state => state.authorization);
    const configuration = useAppSelector((state: RootState) => state.configuration);

    const isUserAndPasswordEnabled = configuration.authentication.usernameAndPassword_isEnabled === true;

    const isSsoEnabled =
        configuration.authentication.adfs_IsEnabled === true ||
        configuration.authentication.azure_IsEnabled === true ||
        configuration.authentication.okta_isEnabled === true;

    const refUrl = getRefUrl(searchParams);
    
    useEffect(()=>{

        if (authorization.signInStatus === ActionStatus.idle){
        
            if (!isUserAndPasswordEnabled && isSsoEnabled){
                
                handleSingleSignOn()     
            }
        }
    }, [isUserAndPasswordEnabled, isSsoEnabled]);
   

    function handleSingleSignOn () {  
        loginWithSSO(configuration.authentication, dispatch);
    }

    return (
        <div className={styles["SignIn"]}>
            <header>
                <h1 className={styles["SignIn-heading1"]}>
                    <Text fontStyle="headline-small" value={pageStrings.signInPage.heading_welcome}></Text>
                </h1>
            </header>
    
            <SignInBannerMessage></SignInBannerMessage>
            
            <div className={styles["SignIn-content-container"]}>
                <div className={styles["SignIn-content"]}>
                    {isUserAndPasswordEnabled && (
                        <SignInUserAndPassword refUrl={refUrl}></SignInUserAndPassword>
                    )}
    
                    {(isUserAndPasswordEnabled && isSsoEnabled) && <Divider></Divider>}
    
                    {isSsoEnabled && (
                        <FilledTonalButton type={"button"} value={pageStrings.signInPage.button_singleSignOn_label} onClick={handleSingleSignOn}></FilledTonalButton>
                    )}
                </div>
            </div>
        </div>
    );
    
}

function getRefUrl (searchParams: URLSearchParams){
    
    let refUrl = searchParams.get("refUrl") ?? urlService.buildLegacyAppUrl("");

    if(!refUrl.includes('tzName=')){

        const joinCharacter = refUrl.includes('?')? '&': '?';

        refUrl = `${refUrl}${joinCharacter}tzName=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`;

    }

    return refUrl;
}

function loginWithSSO (configurationAuthentication: Authentication, dispatch: AppDispatch) { 

    if (configurationAuthentication.azure_IsEnabled) {           
        loginWithAzureSSO(configurationAuthentication, dispatch);
    } else if (configurationAuthentication.okta_isEnabled) {
        loginWithOktaSSO(configurationAuthentication, dispatch);
    } else if (configurationAuthentication.adfs_IsEnabled) {
        loginWithAdfsSSO(configurationAuthentication);
    }

};

function loginWithAzureSSO (configurationAuthentication: Authentication, dispatch: AppDispatch){

    createMsalInstance({
        azure_ClientId: configurationAuthentication.azure_ClientId,
        azure_TenantId: configurationAuthentication.azure_TenantId,
        azure_RedirectUri: configurationAuthentication.azure_RedirectUri,
    }).then((msalInstance)=>{
        msalInstance
            .loginPopup(loginRequest)
            .then((response) => {

                dispatch(signInWithAzure({
                    token:response.accessToken
                }));

            })
            .catch(reason =>{
                appLogService.fatal("SignIn.tsx", 'loginWithAzureSSO', "SignIn", JSON.stringify(reason));
            });
    });
    

}

function loginWithAdfsSSO (configurationAuthentication: Authentication) {

    // ADFS URL and client ID
    const adfsUrl = configurationAuthentication.adfs_OrgUri;
    const clientId =configurationAuthentication.adfs_ClientId;
    
    // Use the specified callback URL for redirection after login
    const redirectUri = encodeURIComponent(configurationAuthentication.adfs_ReturnUrl);

    // Construct the full ADFS URL for SSO
    const loginUrl = `${adfsUrl}?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}&resource=${clientId}`;

    // Redirect the user to ADFS for authentication
    window.location.href = loginUrl;

}

async function loginWithOktaSSO(configurationAuthentication: Authentication, dispatch: AppDispatch){
    try {
        console.log(configurationAuthentication);
        const auth0 = new Auth0Client({
            domain: configurationAuthentication.okta_AccountName ?? "",
            clientId: configurationAuthentication.okta_AppId ?? "",
            authorizationParams: {
                redirect_uri: configurationAuthentication.okta_ReturnUrl,
                audience: configurationAuthentication.okta_AuthorizationServer ?? null,
                responseType: "token id_token",
                scope: "openid profile email",
            },
        });
                
        await auth0.loginWithPopup().then(() => {

            auth0.getIdTokenClaims().then((idToken) => {                   

                if (idToken) {

                    dispatch(signInWithOkta({
                        token: idToken.__raw
                    }));
                }
                           
            });
        });
    } catch (error: any) {
        appLogService.fatal("SignIn.tsx", 'loginWithOktaSSO', "Exception", error.message);
    }
}