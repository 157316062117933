import { useLayout } from "../../../common/hooks";
import { openDialog } from "../../../common/components/material/dialog/Dialog";
import { 
    Dialog,
    FilledButton, 
    FilledSelect, 
    FilledTextField,
    OutlinedTextField,
    FilledTonalButton, 
    Image, 
    OutlinedButton, 
    SelectOption, 
    Switch,
    Text, 
    TextButton,OutlinedSelect
} from '../../../common/components';


import styles from './Components.module.scss';
import { useAppDispatch, useAppSelector } from "../../../store";
import { addSnackbarMessage, SnackbarMessage } from "../../../store/slices/snackbar";
import { NotFound } from "../../errors/notFound/NotFound";


export function Components (){
    const dispatch = useAppDispatch();

    const authorization = useAppSelector((state) => state.authorization);

    useLayout({
        pageTitle: 'Components'
    });
    
 
    function handleShowSnackbar(){
      var sb1 =  new SnackbarMessage('A',5) 
      var sb2 =  new SnackbarMessage('B',5) 
      var sb3 =  new SnackbarMessage('C',5) 
      var sb4 =  new SnackbarMessage('D',5) 
      dispatch(addSnackbarMessage(sb1));
      dispatch(addSnackbarMessage(sb2));
      dispatch(addSnackbarMessage(sb3));
      dispatch(addSnackbarMessage(sb4));
       
    }

    const selectAry =  initSelectArray();
       
    function initSelectArray(){
        let x:{name:string,value:string}[] = []
        for (let i = 0; i < 1500; i++) {
            x.push({name:'option'+i,value:'option'+i});
        }
        return x;
    }


    const SelectOptionAry = selectAry?.map((x, i) => {
        return <SelectOption optionName={x.name}optionValue={x.value}></SelectOption>;
    });

    if(authorization.isSupportLogin)
    {
        return (
            <NotFound></NotFound>
        );
    }
    return (
        <div className={styles['Components']}>
            <section>

                <h2>
                    <Text fontStyle="title-medium" value="Buttons"></Text>
                </h2>

                <section>

                    <h3>
                        <Text fontStyle="title-small" value="Common buttons - Filled"></Text>
                    </h3>

                    <div  className={styles['Components-sectionBody']}>

                        <div className={styles['Components-sectionBodyRow']}>
                            <FilledButton type="button" value="Filled"></FilledButton>
                            <FilledButton type="button" value="Filled" disabled={true}></FilledButton>
                        </div>

                    </div>

                </section>
                
                <section>

                    <h3>
                        <Text fontStyle="title-small" value="Common buttons - Filled Tonal"></Text>
                    </h3>

                    <div className={styles['Components-sectionBody']}>
                        <div className={styles['Components-sectionBodyRow']}>
                            <FilledTonalButton type="button" value="Tonal"></FilledTonalButton>

                        </div>
                    </div>

                </section>

                <section>

                    <h3>
                        <Text fontStyle="title-small" value="Common buttons - Outlined"></Text>
                    </h3>

                    <div className={styles['Components-sectionBody']}>
                        <div className={styles['Components-sectionBodyRow']}>
                            <OutlinedButton type="button" value="Outlined"></OutlinedButton>

                        </div>
                    </div>

                </section>


                <section>

                    <h3>
                        <Text fontStyle="title-small" value="Common buttons - Text"></Text>
                    </h3>

                    <div className={styles['Components-sectionBody']}>
                        <div className={styles['Components-sectionBodyRow']}>

                            <TextButton type="button" value="Text"></TextButton>

                        </div>
                    </div>

                </section>

            </section>

            <section>

                <h2>
                    <Text fontStyle="title-medium" value="Dialog"></Text>
                </h2>

                <div className={styles['Components-sectionBody']}>

                    <div className={styles['Components-sectionBodyRow']}>
                        <OutlinedButton type="button" value="Simple" onClick={()=> openDialog('simple-dialog')}></OutlinedButton>
                    </div>

                </div>

                <Dialog id="simple-dialog" headline={"Dialog"}                    
                    confirmAction= { { label: 'Cancel', type: 'button', action:()=>{console.log('cancel')}}}
                    dismissAction= { { label: 'Ok', type: 'submit', action:(data: any)=> {console.log('ok', data)}}
                    
                }>
                    Just a simple dialog
                </Dialog>
                                    
            </section>

            <section>

                <h2>
                    <Text fontStyle="title-medium" value="Image"></Text>
                </h2>

                <div className={styles['Components-sectionBody']}>
                  
                    <Image alternativeText="image alt" imageSource={'/assets/temp/logo.png'} className={styles['Components-image']} 
                        aspectRatio={"goldenRatio"} orientation="landscape"></Image>

                    <Image alternativeText="image alt" imageSource={'/assets/temp/logo.png'} className={styles['Components-image']} 
                        aspectRatio="goldenRatio" orientation="portrait"></Image>
                </div>

            </section>

            <section>

                <h2>
                    <Text fontStyle="title-medium" value="Select"></Text>
                </h2>

                <div className={styles['Components-sectionBody']}>
                    <FilledSelect label="Enabled">
                        <SelectOption optionName="Option 1" optionValue="option1"></SelectOption>
                        <SelectOption optionName="Option 2" optionValue="option2" disabled={true}></SelectOption>
                        <SelectOption optionName="Option 3" optionValue="option3" selected={true}></SelectOption>
                    </FilledSelect>
                </div>
                <div className={styles['Components-sectionBody']}>
                    <OutlinedSelect label="Enabled">
                        <SelectOption optionName="Option 1" optionValue="option1"></SelectOption>
                        <SelectOption optionName="Option 2" optionValue="option2" disabled={true}></SelectOption>
                        <SelectOption optionName="Option 3" optionValue="option3" selected={true}></SelectOption>
                    </OutlinedSelect>
                </div>

                <div className={styles['Components-sectionBody']}>
                    <FilledSelect label="Performance Test">
                        {SelectOptionAry}
                    </FilledSelect>
                </div>

                                    
            </section>

            <section>

                <h2>
                    <Text fontStyle="title-medium" value="Snackbar (In Progress)"></Text>
                </h2>

                <OutlinedButton
                    type="button"
                    value="Show Snackbar"
                    onClick={handleShowSnackbar}>
                </OutlinedButton>

            </section>

            
            <section>

                <h2>
                    <Text fontStyle="title-medium" value="Switch"></Text>
                </h2>

                <div className={styles['Components-sectionBody']}>

                    <div className={styles['Components-sectionBodyRow']}>
                        <Switch 
                            ariaLabel="swith is enabled"
                            label="Enabled"
                            name="switchEnabled"
                            selected={true}>
                            
                        </Switch>

                        <Switch 
                            ariaLabel="swith is disabled"
                            label="Disabled"
                            name="switchDisabled"
                            disabled={true}
                            selected={true}>
                            
                        </Switch>

                    </div>
                    
                </div>

            </section>

            <section>

                <h2>
                    <Text fontStyle="title-medium" value="TextField"></Text>
                </h2>

                <section>

                    <h3>
                        <Text fontStyle="title-small" value="TextField - Outlined - Input"></Text>
                    </h3>

                    <div className={styles['Components-sectionBody']}>

                        <OutlinedTextField label="Text" type="text" placeholder="text" name="text"></OutlinedTextField>
                       
                        <OutlinedTextField label="Password" type="password" placeholder="password" name="password"></OutlinedTextField>

                    </div>

                </section>
                <section>

                    <h3>
                        <Text fontStyle="title-small" value="TextField - Filled - Input"></Text>
                    </h3>

                    <div className={styles['Components-sectionBody']}>

                        <FilledTextField label="Text" type="text" placeholder="text" name="text"></FilledTextField>

                        <FilledTextField label="Password" type="password" placeholder="password" name="password"></FilledTextField>

                    </div>

                </section>
                                    
            </section>
        </div>
    );

}