import { useLayout } from "../../../common/hooks";
import themeService from "../../../common/services/theme.service";
import { useAppSelector } from "../../../store";
import { NotFound } from "../../errors/notFound/NotFound";
import { TonalPalette } from "./tonalPalette/TonalPalette";

export function Theme () {
   

    const authorization = useAppSelector((state) => state.authorization);
    useLayout({
        pageTitle: 'Theme'
    });

    if(authorization.isSupportLogin)
    {
        return (
            <NotFound></NotFound>
        );
    }
    
    return (
        <>

            <TonalPalette title="Primary" palette={themeService.primaryPalette}></TonalPalette>

            <TonalPalette title="Secondary" palette={themeService.secondaryPalette}></TonalPalette>

            <TonalPalette title="Tertiary" palette={themeService.tertiaryPalette}></TonalPalette>

            <TonalPalette title="Neutral" palette={themeService.neutralPalette}></TonalPalette>

            <TonalPalette title="Neutral Variant" palette={themeService.neutralVariantPalette}></TonalPalette>

            <TonalPalette title="Error" palette={themeService.errorPalette}></TonalPalette>

        </>
    )
}