import { NotFound } from "../app/errors/notFound/NotFound";
import { createRef } from 'react';
import { SignIn } from "../app/intellek/signIn/SignIn";
import { Navigate} from "react-router-dom";

const routes = [   
    // {
    //     index: true,
    //     element: <Navigate to='/support/'/>,        
    // }, 
    {
        index: true,
        nodeRef: createRef(),
        element: <SignIn/>
    },  
    // {
    //     path: '/',
    //     element: <SignIn></SignIn>
    // // },
    // {
    //     path: '*',
    //     element: <SignIn></SignIn>
    // }
];

export default routes;