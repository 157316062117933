
import { usePageStrings } from "../../../../common/hooks";
import { FilledButton, OutlinedTextField, Form} from '../../../../common/components'

import { ActionStatus, RootState, useAppDispatch, useAppSelector } from "../../../../store";
import { intellekSignInWithForm } from "../../../../store/slices/authorization";



export default function SignInUserAndPassword (){
    
    const dispatch = useAppDispatch();
    const pageStrings = usePageStrings();    
    
    const authorization = useAppSelector((state: RootState) => state.authorization);
    
    const formId = "intellek-lms-intellek-signin-form";

    function handleFormSubmit (formData: SignInUsernameAndPasswordFormData){
        dispatch(intellekSignInWithForm({
            password: formData.password,
            username: formData.username
        }));
    }

    return (
        <>
        
            <Form onSubmit={handleFormSubmit} formId={formId} hiddenSubmit>

                <OutlinedTextField name="username" required
                    label={pageStrings.signInPage.formItem_userName_label}  
                    placeholder={pageStrings.signInPage.formItem_userName_placeholder}
                    formid={formId}                    
                    >
                </OutlinedTextField>

                <OutlinedTextField name="password" type="password" required
                    label={pageStrings.signInPage.formItem_password_label}  
                    placeholder={pageStrings.signInPage.formItem_password_placeholder}
                    formid={formId} autocomplete="password"
                    >
                </OutlinedTextField>
                                

                <FilledButton type="submit" 
                    value={pageStrings.signInPage.button_signIn_label}
                    disabled={authorization.signInStatus === ActionStatus.loading || authorization.signInStatus === ActionStatus.succeeded}>
                </FilledButton>

                   
            </Form>
        </>
    );
}



type SignInUsernameAndPasswordFormData = {
    username: string;
    password: string;
};